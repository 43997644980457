<template>
	<modal-wrapper @close="close">
		<div id="dicom-device-dlg" class="dialog" style="padding: 0;">
			<dlg-header title="DICOM Device" @close="close()" />
			<div style="padding: 0 20px 20px 20px">
				<div class="form-row">
					<label class="is-required-right">Description</label>
					<input v-model="device.description" class="input" />
				</div>
				<div class="form-row">
					<label>Station Name</label>
					<input v-model="device.stationName" class="input" />
				</div>
				<div class="form-row">
					<label class="is-required-right">AE Title</label>
					<input
						v-model.trim="device.aeTitle"
						class="input"
						maxlength="16"
						type="text"
						pattern="^[^\\]{1,16}$"
						title="Up to 16 characters.  No backslashes."
					/>
				</div>
				<div class="form-row">
					<label class="is-required-right">Hostname</label>
					<input v-model="device.hostname" class="input" />
				</div>
				<div class="form-row">
					<label class="is-required-right">Port</label>
					<input v-model.number="device.port" class="input" type="number" />
				</div>
				<div class="form-row">
					<label>User AE Title</label>
					<input
						v-model.trim="device.userAeTitle"
						class="input"
						maxlength="16"
						type="text"
						pattern="^[^\\]{1,16}$"
						title="Up to 16 characters.  No backslashes."
					/>
				</div>
				<div v-if="testResult != null" class="form-row test-result">
					Remote Test:
					<span class="status" :class="{ error: testResult.isError }">
						{{ testResult.statusName }}
					</span>
					<a v-if="testResult.logs.length" @click="showTestLog()">
						[View Logs]
					</a>
				</div>
				<div v-if="dicomServiceTestResult" class="form-row test-result">
					Dicom Service Test:
					<span class="status" :class="{ error: dicomServiceTestResult.isError }">
						{{ dicomServiceTestResult.statusName }}
					</span>
				</div>
				<div class="footer right">
					<button class="btn btn-default" @click="test()">Test</button>
					<span style="flex-grow:1"></span>
					<button class="btn btn-danger" @click="deleteDevice()">Delete</button>
					<button class="btn btn-primary" @click="save()">Save</button>
					<button class="btn btn-default" @click="close()">{{ isNew ? 'Cancel' : 'Close' }}</button>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { dicomSend } from '@services/dicomSend'
import { showLog } from './MessageDlg.vue'
import { dicomServiceData } from '@services/dicomServiceData'

const DicomDeviceDlg = {
	name: 'DicomDeviceDlg',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	props: ['original_device'],
	data() {
		return {
			device: null,
			dicomSend,
			dicomServiceTestResult: null,
		}
	},
	computed: {
		isNew() {
			return this.device.id == null
		},
		testResult() {
			return this.dicomSend.items.find(i => i.queueItemId === this.testItemId)
		},
	},
	created() {
		this.device = JSON.parse(JSON.stringify(this.original_device))
	},
	methods: {
		showTestLog() {
			showLog(this.testResult.logs)
		},
		close() {
			dlg.close(this, true, false)
		},
		deleteDevice() {
			dicomSend.deleteDevice(this.device).then(r => dlg.close(this, true, true))
		},
		async test() {
			if (!dicomSend.validateDevice(this.device)) return
			this.testItemId = await dicomSend.sendEcho(this.device)
			if (dicomServiceData.hasLocalConnection) {
				this.dicomServiceTestResult = {
					isError: false,
					statusName: 'Pending',
				}
				let success = await dicomServiceData.sendEcho(this.device)
				this.dicomServiceTestResult.isError = !success
				this.dicomServiceTestResult.statusName = success ? 'Success' : 'Failed'
			}
		},
		save() {
			if (!dicomSend.validateDevice(this.device)) return
			dicomSend.saveDevice(this.device).then(r => dlg.close(this, true, true))
		},
	},
}

export default DicomDeviceDlg

export function openDicomDeviceDlg(device) {
	return dlg.open(DicomDeviceDlg, { original_device: device })
}
</script>

<style lang="scss">
#dicom-device-dlg {
	.test-result {
		.status {
			color: var(--icon-success);
			&.error {
				color: var(--icon-danger);
			}
		}

		a {
			font-size: 0.7em;
		}
	}
}
</style>
