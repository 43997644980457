<template>
	<modal-wrapper @close="close">
		<!-- Entire modal is drop target if only image uploads are available -->
		<div class="dicom-service-queue">
			<dlg-header class="queue-header" title="DICOM Service Queues" @close="close" />
			<div class="queue-body">
				<div class="top">
					<h4>
						Local Service <span style="font-weight: normal">on</span> {{ dicomServiceData.localService.hostName }}
					</h4>
					<div style="padding-left: 12px;">
						<button class="btn btn-default" @click="togglePaused">
							<template v-if="paused">
								<svg-icon icon="play" fixed />
								Resume archiving
							</template>
							<template v-else>
								<svg-icon icon="pause" fixed />
								Pause archiving
							</template>
						</button>
					</div>
				</div>
				<ul v-if="!isCommunity" class="tabs">
					<li :class="{ 'is-active': activeTab === 'archiving' }" @click="activeTab = 'archiving'">
						Archiving Queue
					</li>
					<li :class="{ 'is-active': activeTab === 'auto-routing' }" @click="activeTab = 'auto-routing'">
						Auto-Routing Queue
					</li>
				</ul>
				<div v-if="activeTab === 'archiving'" key="archiving" style="padding: 12px 16px;">
					<transition-group name="expand">
						<service-queue-study
							v-for="(study, i) in sortedStudies"
							:key="study.studyUid"
							v-bind="study"
							draggable
							@close="close"
							@view-logs="viewLogs"
							@move-top="moveTop"
							@move-up="moveUp"
							@move-down="moveDown"
							@move-bottom="moveBottom"
							@dragover.native.prevent="onDragOver($event, i)"
							@dragstart.native="onDragStart($event, study.studyUid, i)"
							@dragenter.native="onDragEnter($event, i)"
							@dragleave.native="onDragLeave($event, i)"
							@drop.native="onDrop($event, i)"
							@dragend.native="onDragEnd"
						/>
					</transition-group>
					<p v-if="!sortedStudies.length" style="text-align: center;"> No studies are in the archiving queue. </p>
				</div>
				<div v-else key="auto-routing" style="padding: 12px 16px;">
					<transition-group name="expand">
						<div
							v-for="study in autoRoutingQueue"
							:key="study.studyUid + study.aeTitle"
							class="queue-study"
							style="cursor: initial;"
						>
							<div class="handle-icon-details">
								<svg-icon icon="clock-o" class="is-muted" />
								<div class="details">
									<p v-if="study.patientName || study.patientId">
										<strong>{{ [study.patientName, study.patientId].join(' - ') }}</strong>
									</p>
									<p v-if="study.studyDate || study.modality" style="font-size: 0.8em;">
										{{ study.studyDate | localizeDate({ forceUTC: true, showTime: false }) }}
										<b v-if="study.modality" style="padding-left: 8px;">{{ study.modality }}</b>
									</p>
								</div>
							</div>
							<div class="status-progress" style="min-width: 50%; align-items: flex-start;">
								<p>
									{{ study.imageCount }} Images
									<svg-icon icon="arrow-right" class="is-muted" fixed />
									<b>{{ study.aeTitle }}</b>
									&nbsp;
									<span v-if="study.hostname" class="is-muted">({{ study.hostname }}:{{ study.port }})</span>
								</p>
								<p v-if="study.hasError" class="is-danger" style="margin-top: 8px;">
									Errors were encountered for this study.<br />
									<a @click="viewLogs">View log &hellip;</a>
								</p>
							</div>
						</div>
					</transition-group>
					<p v-if="!autoRoutingQueue.length" style="text-align: center;">
						No studies are in the auto-routing queue.
					</p>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import { dicomServiceData } from '@services/dicomServiceData'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper'
import { openDicomServiceLogDlg } from '@dialogs/DicomServiceLogDlg.vue'
import ServiceQueueStudy from '@components/ServiceQueueStudy'
import { dicomSend } from '@services/dicomSend'

import { dlg } from '@utils/dlgUtils'

const DicomServiceQueue = {
	name: 'DicomServiceQueue',
	components: {
		DlgHeader,
		ModalWrapper,
		ServiceQueueStudy,
	},
	data() {
		return {
			dicomServiceData,
			dicomPeers: [],
			activeTab: 'archiving',
			paused: false,
			reorderedStudyUids: [], // populated once the user reprioritizes the queue
			draggingIndex: null,
			draggingStudyUid: null,
			dragOverIndex: null,
		}
	},
	computed: {
		sortedKeys() {
			const keys = Array.from(this.dicomServiceData.localStudies.keys())
			if (!this.reorderedStudyUids.length) return keys
			return keys.sort((a, b) => {
				const aIndex = this.reorderedStudyUids.indexOf(a)
				const bIndex = this.reorderedStudyUids.indexOf(b)
				if (aIndex !== -1 && bIndex === -1) return -1
				if (aIndex === -1 && bIndex !== -1) return 1
				if (aIndex < bIndex) return -1
				if (aIndex > bIndex) return 1
				return 0
			})
		},
		sortedStudies() {
			if (!this.reorderedStudyUids.length) return Array.from(this.dicomServiceData.localStudies.values())
			return this.sortedKeys.map(uid => this.dicomServiceData.localStudies.get(uid))
		},
		isCommunity() {
			return this.$store.getters.isCommunityUser
		},
		autoRoutingQueue() {
			const queue = []
			for (const [studyUid, study] of this.dicomServiceData.localStudies) {
				if (!study.pendingAutoRoutes) continue
				Object.keys(study.pendingAutoRoutes).forEach(peerId => {
					if (study.pendingAutoRoutes[peerId]) {
						const peer = this.dicomPeers.find(p => p.id === peerId)
						queue.push({
							...study,
							studyUid,
							imageCount: study.pendingAutoRoutes[peerId],
							aeTitle: peer?.aeTitle || 'Unknown Device',
							hostname: peer?.hostname,
							port: peer?.port,
							hasError: this.dicomServiceData.localService?.recentErrors.some(e => e.studyUid === studyUid),
						})
					}
				})
			}
			return queue
		},
	},
	async created() {
		this.paused = dicomServiceData.isLocalServicePaused
		this.dicomPeers = await dicomSend.getDevices()
	},
	methods: {
		saveQueueOptions() {
			dicomServiceData.setServiceQueueOptions({
				studyUidPriorities: this.reorderedStudyUids,
				paused: this.paused,
			})
		},
		togglePaused() {
			this.paused = !this.paused
			this.saveQueueOptions()
		},
		viewLogs() {
			openDicomServiceLogDlg(this.dicomServiceData.localService)
		},
		moveStudyUid({ studyUid, delta, to = null, toTop = false, toBottom = false }) {
			if (this.sortedKeys.length < 2) return
			const sortedKeys = this.sortedKeys.slice()
			const from = sortedKeys.indexOf(studyUid)
			if (from === -1) return
			if (from === 0 && (delta === -1 || toTop)) return
			if (from === sortedKeys.length - 1 && (delta === 1 || toBottom)) return
			if (to === null) to = delta ? from + delta : toTop ? 0 : sortedKeys.length - 1
			sortedKeys.splice(to, 0, sortedKeys.splice(from, 1)[0])
			this.reorderedStudyUids = sortedKeys
			if (this.reorderedStudyUids.length > 1) this.saveQueueOptions()
		},
		moveUp(studyUid) {
			this.moveStudyUid({ studyUid, delta: -1 })
		},
		moveDown(studyUid) {
			this.moveStudyUid({ studyUid, delta: 1 })
		},
		moveTop(studyUid) {
			this.moveStudyUid({ studyUid, toTop: true })
		},
		moveBottom(studyUid) {
			this.moveStudyUid({ studyUid, toBottom: true })
		},
		onDragOver(e, i) {
			e.dataTransfer.dropEffect = 'move'
		},
		onDragEnter(e, i) {
			e.target.classList.add(i < this.draggingIndex ? 'drop-before' : 'drop-after')
			this.dragOverIndex = i
		},
		onDragLeave(e) {
			e.target.classList.remove('drop-before', 'drop-after')
			this.dragOverIndex = null
		},
		onDrop(e, i) {
			e.target.classList.remove('drop-before', 'drop-after')
			this.moveStudyUid({ studyUid: this.draggingStudyUid, to: i })
		},
		onDragStart(e, studyUid, i) {
			this.draggingStudyUid = studyUid
			this.draggingIndex = i
		},
		onDragEnd() {
			this.draggingIndex = null
			this.dragOverIndex = null
			this.draggingStudyUid = null
		},
		close() {
			dlg.close(this)
		},
	},
}

export default DicomServiceQueue

export function openDicomServiceQueue() {
	return dlg.open(DicomServiceQueue, null, false)
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.dicom-service-queue {
	display: flex;
	position: relative;
	flex-direction: column;
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	width: 90vw;
	max-width: 1000px;
	height: 90vh;
	overflow: hidden;
	transition: height 0.2s ease;
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 1.75em;
		background: var(--secondary-bg);
		border-bottom: 1px solid var(--secondary-border);
		padding: 16px;
	}
	@media (max-width: $mqSmall) {
		height: 100vh;
		height: -webkit-fill-available;
		width: 100vw;
		.top,
		.top .btn,
		.top .btn .icon {
			font-size: 0.9em;
		}
	}
	.queue-header {
		flex-shrink: 0;
		border-bottom: 1px solid var(--card-border);
	}
	.queue-body {
		flex-grow: 1;
		background: var(--primary-bg);
		overflow: auto;
		display: flex;
		flex-direction: column;
	}
	.tabs {
		background: var(--primary-bg);
	}
	.modal-upload-form {
		padding: 40px;
		&.upload {
			background: transparent;
		}
	}
	.attachment-study-form {
		background: var(--secondary-bg);
		border: 1px solid var(--secondary-border);
		padding: 15px 30px 15px 15px;
	}
	.upload-items {
		padding: 30px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		flex-grow: 1;
		min-height: 0;
		background: var(--primary-bg);
		.study {
			margin: 15px auto;
		}
		ol {
			list-style-type: none;
			margin: auto;
			position: relative;
			button.close {
				position: absolute;
				top: 0;
				right: 0;
				padding: 15px;
				background: transparent;
				border: 0;
			}
		}
	}
}
</style>
