





























































import { openDicomDeviceDlg } from '@dialogs/DicomDeviceDlg.vue'
import { dicomServiceData } from '@services/dicomServiceData'

const comparisons = Array.from(
	[
		'equals',
		'does not equal',
		'is less than',
		'is less than or equal to',
		'is greater than',
		'is greater than or equal to',
		'contains',
		'does not contain',
	].entries()
).map(c => ({ label: c[1], value: c[0] }))

const commonDicomTagsIds = [
	524368, // AccessionNumber
	524416, // InstitutionName
	524384, // Modality
	1048608, // PatientID
	1048592, // PatientsName
	1057431, // ResponsiblePerson
	528432, // StudyDescription
	528446, // SeriesDescription
	524320, // StudyDate
	524432, // ReferringPhysiciansName
	528456, // PhysiciansOfRecord
	528480, // NameOfPhysiciansReadingStudy
	528464, // PerformingPhysiciansName
	524400, // Manufacturer
	528528, // ManufacturerModelName
]
let commonDicomTags = null

export default {
	name: 'DicomAutoRouteRule',
	model: {
		prop: 'rule',
		event: 'input',
	},
	props: {
		rule: {
			type: Object,
			required: true,
		},
		dicomPeers: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			dicomTags: [],
			comparisons,
		}
	},
	watch: {
		'rule.dicomPeerId'(id) {
			if (id === 'NEW') {
				this.$nextTick(() => {
					this.rule.dicomPeerId = null
				})
				this.newDevice()
			}
		},
	},
	mounted() {
		this.initCommonDicomTags()
	},
	methods: {
		async initCommonDicomTags() {
			// Load dicom tags once
			if (!commonDicomTags) {
				// Load tags and filter by list of dicom tag ids
				const tags = await dicomServiceData.searchDicomTags('')
				commonDicomTags = tags.filter(t => commonDicomTagsIds.some(id => id === t.id))
			}
			// Assign local dicom tags
			this.dicomTags = commonDicomTags
		},
		addCondition() {
			this.rule.conditions.push({
				dicomTag: null,
				comparison: 0,
				value: null,
			})
		},
		removeCondition(i) {
			this.rule.conditions.splice(i, 1)
		},
		async newDevice() {
			let r = await openDicomDeviceDlg({
				id: null,
				description: null,
				stationName: null,
				aeTitle: null,
				hostname: null,
				port: 0,
				userAeTitle: null,
			})
			if (r === true) {
				this.$emit('refresh-peers')
			}
		},
		async searchDicomTags(term: string) {
			try {
				if (term) {
					this.dicomTags = await dicomServiceData.searchDicomTags(term)
				} else {
					this.dicomTags = commonDicomTags
				}
			} catch (err) {
				this.dicomTags = commonDicomTags
			}
		},
	},
}
