<template>
	<modal-wrapper @close="close">
		<div id="dicomPeersDlg" class="dialog" style="padding: 0;">
			<dlg-header title="DICOM Peers" @close="close()" />
			<div style="padding: 0 20px 20px 20px">
				<div v-if="!devices.length">
					No DICOM Device Peers
				</div>
				<table v-else>
					<thead>
						<tr>
							<th>Description</th>
							<th>AE Title</th>
							<th>Hostname</th>
							<th>Port</th>
							<th>User AE Title</th>
							<th>Name</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="device in devices" :key="device.id" class="device">
							<td>{{ device.description }}</td>
							<td>{{ device.aeTitle }}</td>
							<td>{{ device.hostname }}</td>
							<td>{{ device.port }}</td>
							<td>{{ device.userAeTitle }}</td>
							<td>{{ device.stationName }}</td>
							<td>
								<a @click="viewDevice(device)">Edit</a>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="footer right">
					<button class="btn btn-primary" @click="newDevice()">New Device</button>
					<button class="btn btn-default" @click="close()">Close</button>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { dicomSend } from '@services/dicomSend'
import { openDicomDeviceDlg } from './DicomDeviceDlg.vue'

const DicomPeersDlg = {
	name: 'DicomPeersDlg',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	data() {
		return {
			devices: [],
		}
	},
	methods: {
		close() {
			dlg.close(this)
		},
		newDevice() {
			this.viewDevice({
				id: null,
				description: null,
				stationName: null,
				aeTitle: null,
				hostname: null,
				port: 0,
				userAeTitle: null,
			})
		},
		async viewDevice(device) {
			let r = await openDicomDeviceDlg(device)
			if (r === true) {
				this.devices = await dicomSend.getDevices()
			}
		},
	},
	async created() {
		this.devices = await dicomSend.getDevices()
	},
}

export default DicomPeersDlg

export function openDicomPeersDlg() {
	return dlg.open(DicomPeersDlg, {})
}
</script>
