<template>
	<div class="queue-study">
		<div class="handle-icon-details">
			<div class="drag-handle" title="Drag to reorder">
				<svg-icon icon="drag-vertical" inline class="is-muted" />
			</div>
			<svg-icon :icon="icon" :pulse="icon === 'spinner'" class="status-icon" :class="iconClass" />
			<div class="details">
				<p v-if="patientName || patientId">
					<strong>{{ [patientName, patientId].join(' - ') }}</strong>
				</p>
				<p v-if="studyDate || modality" style="font-size: 0.8em;">
					{{ studyDate | localizeDate({ forceUTC: true, showTime: false }) }}
					<b v-if="modality" style="padding-left: 8px;">{{ modality }}</b>
				</p>
			</div>
		</div>
		<div class="status-progress">
			<template v-if="isUploading">
				<progress-bar :width="uploadPercent" style="width: 100%;" />
				{{ uploadPercent }}%
			</template>
			<p v-if="hasError" class="is-danger" :style="{ marginTop: hasError && isUploading ? '8px' : 0 }">
				Errors were encountered for this study.<br />
				<a @click="$emit('view-logs')">View log &hellip;</a>
			</p>
		</div>
		<div class="buttons">
			<button :disabled="isLoadingStudyId" class="btn btn-default" style="font-size: 0.75em;" @click.stop="viewStudy">
				<svg-icon icon="eye" fixed />
				View
			</button>
			<button class="btn btn-default" @click.stop="$emit('move-top', studyUid)">
				<svg-icon icon="skip-next" style="transform: rotate(-90deg)" />
			</button>
			<button class="btn btn-default" @click.stop="$emit('move-up', studyUid)">
				<svg-icon icon="caret-down" style="transform: rotate(180deg)" />
			</button>
			<button class="btn btn-default" @click.stop="$emit('move-down', studyUid)">
				<svg-icon icon="caret-down" />
			</button>
			<button class="btn btn-default" @click.stop="$emit('move-bottom', studyUid)">
				<svg-icon icon="skip-next" style="transform: rotate(90deg)" />
			</button>
		</div>
	</div>
</template>

<script>
import ProgressBar from '@components/ProgressBar'
import { dicomServiceData } from '@services/dicomServiceData'

export default {
	name: 'ServiceQueueStudy',
	components: {
		ProgressBar,
	},
	props: {
		studyUid: {
			type: String,
			required: true,
		},
		patientId: {
			type: String,
			default: null,
		},
		patientName: {
			type: String,
			default: null,
		},
		studyDate: {
			type: String,
			default: null,
		},
		modality: {
			type: String,
			default: null,
		},
		pendingImages: {
			type: Number,
			default: null,
		},
		totalImages: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			isLoadingStudyId: false,
			studyId: null,
			dicomServiceData,
		}
	},
	computed: {
		uploadPercent() {
			const uploadedCount = this.totalImages - this.pendingImages
			return Math.round((uploadedCount / this.totalImages) * 100)
		},
		isUploading() {
			return this.pendingImages > 0 && this.totalImages !== this.pendingImages
		},
		hasError() {
			return this.dicomServiceData.localService?.recentErrors.some(e => e.studyUid === this.studyUid)
		},
		icon() {
			if (this.isUploading) return 'spinner'
			if (this.uploadPercent === 100) return 'check-circle'
			if (this.hasError) return 'exclamation-circle'
			return 'clock-o'
		},
		iconClass() {
			if (this.isUploading) return 'is-muted'
			if (this.uploadPercent === 100) return 'is-success'
			if (this.hasError) return 'is-danger'
			return 'is-muted'
		},
	},
	methods: {
		async viewStudy() {
			if (!this.studyId) this.isLoadingStudyId = true
			try {
				if (!this.studyId) {
					this.studyId = await dicomServiceData.getStudyId(this.studyUid)
				}
				await this.$store.dispatch('openStudy', { studyId: this.studyId })
				this.$emit('close')
			} finally {
				this.isLoadingStudyId = false
			}
		},
		onDragStart(event) {
			event.dataTransfer.effectAllowed = 'move'
			event.dataTransfer.setData('text', '')
			if (event.dataTransfer.setDragImage) {
				// show entire row as drag image
				const dragImage = this.$el
				const rect = dragImage.getBoundingClientRect()
				event.dataTransfer.setDragImage(dragImage, 32, rect.height / 2)
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.queue-study {
	display: flex;
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	position: relative;
	margin: 4px 0;
	max-width: 1000px;
	cursor: grab;
	.handle-icon-details {
		display: flex;
		flex-grow: 1;
		align-items: center;
		& > * {
			margin: 12px;
		}
	}
	.drag-handle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		user-select: none;
		& > * {
			pointer-events: none;
		}
	}
	.buttons {
		display: flex;
		align-items: center;
		margin: 8px;
		button:first-child {
			margin-right: 8px;
		}
		button {
			min-height: 33px;
			cursor: pointer;
			margin: 0.5px;
			.icon {
				opacity: 1;
			}
		}
	}
	.status-progress {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 16px;
		font-size: 0.9em;
		max-width: 30%;
		min-width: 30%;
	}
	.status-icon {
		font-size: 1.5em;
	}
	&.drop-after {
		box-shadow: 0 2px 0 0 var(--icon-success);
		& > * {
			pointer-events: none;
		}
	}
	&.drop-before {
		box-shadow: 0 -2px 0 0 var(--icon-success);
		& > * {
			pointer-events: none;
		}
	}
}
</style>
