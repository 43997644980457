<template>
	<modal>
		<div id="dicom-how-to-install-dlg" class="dialog">
			<iframe src="/dicom_how_to_install/DicomHowToInstall.html"></iframe>
			<div class="footer right">
				<button class="btn btn-default" @click="close()">Close</button>
			</div>
		</div>
	</modal>
</template>

<script>
import { dlg } from '@utils/dlgUtils'
import modal from '@components/ModalWrapper.vue'

const DicomHowToInstallDlg = {
	name: 'DicomHowToInstall',
	components: {
		modal,
	},
	methods: {
		close() {
			dlg.close(this)
		},
	},
}

export default DicomHowToInstallDlg

export function openDicomHowToInstallDlg() {
	return dlg.open(DicomHowToInstallDlg)
}
</script>

<style lang="scss">
#dicom-how-to-install-dlg {
	width: 60vw;
	height: 60vh;
	min-width: 600px;
	min-height: 500px;
	display: flex;
	flex-direction: column;

	iframe {
		border: 0;
		flex-grow: 1;
	}
}
</style>
