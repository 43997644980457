<template>
	<modal-wrapper @close="close">
		<div id="dicomServiceDlg" class="dialog flex">
			<div
				v-if="!dicomServiceData.dicomServices || !dicomServiceData.dicomServices.length"
				style="max-width:600px; line-height:1.6; padding: 16px;"
			>
				<p style="margin-bottom:10px">
					Our
					<strong>DICOM service</strong>
					will make it easy for you to transmit DICOM images directly from your modality into Keystone.
				</p>
				<p v-if="userData.claims.isCommunityUser">
					Click “Create New Service” to configure and download the DICOM Service application.
				</p>
				<p v-else>
					No Services Found, Please <a @click="contactSupport()">Contact Support</a> to request a download.
				</p>
			</div>
			<template v-else>
				<dlg-header title="DICOM Services" @close="close()" />
				<div class="body">
					<div class="tiles">
						<div v-for="service in enabledServices" :key="service.id" class="tile">
							<div class="header">
								<span
									:class="{
										'is-muted': service.status === 'Offline' || service.disabled,
										'is-success': service.status != 'Offline' && !service.pending && !service.disabled,
									}"
								>
									<svg-icon :icon="getStatusIcon(service)" :spin="service.isActive" />
									<span class="status">{{ service.disabled ? 'Disabled' : service.status }}</span>
								</span>
								<div v-if="service === dicomServiceData.localService">
									<span class="this-pc-tag">
										<svg-icon icon="star" />
										THIS PC
									</span>
								</div>
							</div>
							<div class="content">
								<div v-if="!service.pending" class="form-row">
									<label>Client:</label>
									<div style="font-size: 0.95em;">
										<b>{{ service.hostName }}</b> [{{ service.hostIPAddress }}]
									</div>
									<div style="font-size: 0.75em;">v{{ service.version }}</div>
								</div>
								<div class="form-row">
									<label>AE Title:</label>
									<span>{{ service.dicomAeTitle }}</span>
								</div>
								<div style="margin-top: 8px;">
									<label>DICOM Port:</label>
									<span>{{ service.dicomPort }}</span>
								</div>
								<div v-if="service.dicomFolder" class="form-row">
									<label>Directory:</label>
									<span style="font-size: 0.9em;">{{ service.dicomFolder }}</span>
								</div>
								<div v-if="!service.pending" class="form-row">
									<label>Last Check-in:</label>
									<span>{{ service.lastCheckIn | localizeDate }}</span>
									<span>&nbsp;({{ service.checkinMS | msToDuration }})</span>
								</div>
								<transition name="expand">
									<div
										v-if="service === dicomServiceData.localService && dicomServiceData.localServiceHasErrors"
										class="has-errors is-danger"
									>
										<svg-icon icon="exclamation-circle" />
										<p> Errors encountered while archiving.<br /> </p>
									</div>
								</transition>
							</div>
							<div class="actions">
								<a class="blue" @click="configureService(service)">
									<svg-icon icon="edit" fixed />
									<span>Configure</span>
								</a>
								<a class="blue" @click="viewLogs(service)">
									<svg-icon icon="file-document-box-outline" fixed />
									<span>Logs</span>
								</a>
								<a v-if="showQueue(service)" class="blue" @click="openDicomServiceQueue">
									<svg-icon icon="play-box-outline" fixed />
									<span>Queues</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</template>

			<div class="footer right" style="margin: 0; padding: 16px;">
				<button v-if="userData.claims.isCommunityUser" class="btn btn-primary" @click="newService()"
					>Create New Service</button
				>
				<button class="btn btn-default" @click="close()">Close</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { dicomServiceData } from '@services/dicomServiceData'
import { openDicomServiceLogDlg } from '@dialogs/DicomServiceLogDlg.vue'
import { openDicomServiceEditDlg } from './DicomServiceEditDlg.vue'
import { openDicomServiceQueue } from './DicomServiceQueue.vue'
import DlgHeader from '@components/DlgHeader.vue'
import { userData } from '@services/userData'
import { openReportIssue } from './ReportIssue.vue'
import { eventBus } from '@services/eventBus'

const DicomServiceDlg = {
	name: 'DicomServiceDlg',
	components: {
		ModalWrapper,
		DlgHeader,
	},
	data() {
		return {
			dicomServiceData,
			userData,
		}
	},
	computed: {
		enabledServices() {
			return this.dicomServiceData.dicomServices.filter(s => !s.disabled)
		},
	},
	created() {
		dicomServiceData.getServices()
	},
	methods: {
		openDicomServiceQueue,
		download(service) {
			let platform = dicomServiceData.getPlatform()
			dicomServiceData.downloadPackage(platform, service.regToken)
		},
		getStatusIcon(service) {
			if (service.status === 'Offline' || service.disabled) return 'cancel'
			if (service.pending) return 'clock-o'
			if (!service.isActive) return 'check-circle'
			return 'spinner-circle-thick'
		},
		showQueue(service) {
			return service === this.dicomServiceData.localService && service.checkinMS < 60000 && !service.disabled
		},
		newService() {
			let service = {
				id: null,
				hostName: '',
				hostIPAddress: '',
				dicomAeTitle: userData.claims.isCommunityUser ? 'Community' : 'Keystone',
				dicomPort: 7001,
				dicomFolder: '',
			}
			openDicomServiceEditDlg(service).then(() => dicomServiceData.getServices())
		},
		configureService(service) {
			openDicomServiceEditDlg(service).then(() => dicomServiceData.getServices())
		},
		viewLogs(service) {
			openDicomServiceLogDlg(service, null)
		},
		contactSupport() {
			this.close()
			eventBus.once('routechanged', () => {
				openReportIssue({ clinicCode: userData.claims.activeClinicId })
			})
		},
		close() {
			dlg.close(this)
		},
	},
}

export default DicomServiceDlg

export function openDicomServiceDlg() {
	return dlg.open(DicomServiceDlg)
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

#dicomServiceDlg {
	padding: 0;

	@media (max-width: $mqSmall) {
		height: 100vh;
		height: -webkit-fill-available;
	}

	.downloadLink {
		display: block;
		margin-top: 6px;
	}

	.body {
		flex-grow: 1;
		overflow: auto;
		background: var(--primary-bg);
		border: 1px solid var(--secondary-border);
		border-left: 0;
		border-right: 0;
	}

	.tiles {
		padding: 12px;
		display: flex;
		flex-wrap: wrap;
	}

	.tile {
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;
		border: 1px solid var(--card-border);
		background: var(--card-bg);
		margin: 4px;
		border-top-width: 2px;
		min-width: 200px;

		label {
			opacity: 0.5;
			margin-right: 10px;
		}

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 12px;
			border-bottom: 1px solid var(--card-border);
			.status {
				font-size: 0.85em;
				font-weight: 700;
				margin-left: 4px;
			}
		}

		.content {
			padding: 0 12px 12px 12px;
			flex-grow: 1;
		}

		.has-errors {
			display: flex;
			align-items: center;
			font-size: 0.85em;
			font-weight: 700;
			margin-top: 8px;
			:first-child {
				font-size: 1.15em;
				margin-right: 4px;
			}
		}

		.this-pc-tag {
			font-size: 0.85em;
			font-weight: 700;
		}

		.form-row:not(:first-of-type) {
			margin-top: 8px;
		}

		.actions {
			display: flex;
			align-items: center;
			padding: 12px;
			border-top: 1px solid var(--card-border);
			& > * {
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					font-size: 0.85em;
					font-weight: 700;
				}
			}
			& > * + * {
				margin-left: 12px;
			}
		}

		input {
			width: 100%;
		}
	}
}
</style>
