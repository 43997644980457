






























































































import DicomAutoRouteRule from '@components/DicomAutoRouteRule.vue'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { dicomServiceData } from '@services/dicomServiceData'
import { dicomSend } from '@services/dicomSend'
import { openDicomHowToInstallDlg } from '@dialogs/DicomHowToInstallDlg.vue'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { openDicomPeersDlg } from '@dialogs/DicomPeersDlg.vue'

const DICOM_ACTIONS = {
	MOVE: 1,
}

const DicomServiceEditDlg = {
	name: 'DicomServiceEditDlg',
	components: {
		DicomAutoRouteRule,
		DlgHeader,
		ModalWrapper,
	},
	props: ['service'],
	data() {
		return {
			platform: 'win-x86',
			rules: [],
			dicomPeers: [],
		}
	},
	computed: {
		isNew() {
			return this.service.id == null
		},
		isCommunity() {
			return this.$store.getters.isCommunityUser
		},
	},
	async created() {
		this.platform = dicomServiceData.getPlatform()
		await this.getDicomPeers()
		this.rules = JSON.parse(this.service.autoRoutingJson || '[]')
	},
	methods: {
		openDicomPeersDlg,
		async getDicomPeers() {
			const dicomPeers = await dicomSend.getDevices()
			this.dicomPeers = [{ id: 'NEW', aeTitle: 'Add new device ...' }, ...dicomPeers]
		},
		addRule() {
			const rules = this.rules.slice()
			rules.push({
				conditions: [
					{
						dicomTag: null,
						comparison: 0,
						value: '',
					},
				],
				action: DICOM_ACTIONS.MOVE,
				dicomPeerId: null,
				disabled: false,
			})
			this.rules = rules
		},
		deleteRule(i) {
			this.rules.splice(i, 1)
		},
		async download() {
			if (this.isNew) {
				let token = await dicomServiceData.createService(
					this.service.dicomAeTitle,
					this.service.dicomPort,
					this.service.dicomFolder
				)
				dicomServiceData.downloadPackage(this.platform, token)
				await openDicomHowToInstallDlg()
				this.close()
			} else {
				dicomServiceData.downloadPackage(this.platform, this.service.regToken)
			}
		},
		toggleService() {
			this.service.disabled = !this.service.disabled
			this.save()
		},
		async save() {
			let aeTitle = this.service.dicomAeTitle
			if (!/^[^\\]{1,16}$/.test(aeTitle)) {
				showAlert('AE Title should be 1-16 characters.  Blackslashes are not allowed.')
				return
			}

			const isInvalidRule = r =>
				!r.dicomPeerId || r.dicomPeerId === 'NEW' || r.conditions.some(c => c.value == null || !c.dicomTag)
			if (this.rules.some(isInvalidRule)) {
				showAlert('Auto-routing rules must specify a DICOM tag, value, and DICOM peer.')
				return
			}

			this.service.autoRoutingJson = JSON.stringify(this.rules)

			if (this.service.dicomPort > 65535 || this.service.dicomPort < 1) {
				showAlert('The DICOM Port number must be between 1 and 65535.')
				return
			}

			await dicomServiceData.saveService(this.service)
			this.close()
		},
		close() {
			dlg.close(this)
		},
	},
}

export default DicomServiceEditDlg

export function openDicomServiceEditDlg(service) {
	return dlg.open(DicomServiceEditDlg, { service })
}
