<template>
	<modal-wrapper @close="close">
		<div id="report-issue" class="dialog">
			<dlg-header title="Report Issue" @close="close" />
			<div style="padding: 0 16px 16px 16px;">
				<div class="form-row">
					<label class="is-required-right">Name</label>
					<input v-model.trim="name" class="input" />
				</div>
				<div class="form-row">
					<label class="is-required-right">Email</label>
					<input v-model.trim="email" class="input" />
				</div>
				<div class="form-row">
					<label class="is-required-right">Phone Number</label>
					<input v-model.trim="phone" class="input" />
				</div>
				<div class="form-row">
					<p>
						Please explain the issue you are experiencing. Asteris personnel will review your report as soon as possible
						and may contact you for further information if required.
					</p>
					<textarea v-model.trim="message" autofocus></textarea>
				</div>
				<div class="footer right">
					<button class="btn btn-primary" :disabled="!canSubmit" @click="submit()">
						Submit
					</button>
					<button class="btn btn-default" @click="close()">Close</button>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import { clinicAPI } from '@services/clinicAPI'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'

const ReportIssue = {
	name: 'ReportIssue',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	props: {
		clinicCode: {
			type: String,
			default: null,
		},
		sentryEventId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isSending: false,
			message: '',
			name: '',
			phone: '',
			email: '',
		}
	},
	computed: {
		canSubmit() {
			return !this.isSending && this.message && this.name && this.phone && this.email
		},
	},
	methods: {
		async submit() {
			if (!this.message.trim() || this.isSending) return
			this.isSending = true
			try {
				await this.$api.reportIssue({
					clinicCode: this.clinicCode,
					message: this.message,
					url: window.location.href,
					data: this.getStateJson(),
					name: this.name,
					phone: this.phone,
					email: this.email,
					isDesktopClient: !!window.ElectronInterface,
				})
				this.$store.dispatch('addNotification', {
					message: 'Your issue has been reported.',
					notificationType: 'success',
				})
				this.close()
			} finally {
				this.isSending = false
			}
		},
		getStateJson() {
			const filterState = (key, value) => {
				const excludeKeys = ['vue'] // exclude canvas.vue refs to avoid circular references
				const stringLimit = 8192 // avoid sending base64-encoded thumbnails
				const isTooLong = value && typeof value === 'string' && value.length > stringLimit
				if (excludeKeys.includes(key) || isTooLong) return '[ excluded from JSON serialization ]'
				return value
			}
			const state = {
				sentryEventId: this.sentryEventId,
				connectionStatus: clinicAPI.activeClinicUrl ? `Local server (${clinicAPI.importMachineName})` : 'Cloud',
				desktopClientOS: window.ElectronInterface
					? window.ElectronInterface.os || 'Unknown'
					: 'N/A - Not using desktop client',
				...this.$store.state,
			}
			return JSON.stringify(
				state,
				filterState,
				'\t' // pretty print
			)
		},
		close() {
			dlg.close(this)
		},
	},
}

export default ReportIssue

export function openReportIssue({ clinicCode, sentryEventId }) {
	return dlg.open(ReportIssue, { clinicCode, sentryEventId })
}
</script>

<style lang="scss">
#report-issue {
	padding: 0;
	width: 500px;

	textarea {
		font-size: inherit;
		font-family: inherit;
		padding: 10px;
		margin-top: 16px;
		width: 100%;
		height: 100px;
	}
}
</style>
